import { gql } from '@apollo/client'

export const M_NOTIFICATION_READ = gql`
  mutation ReadNotification($profileId: Int!) {
    update_notifications(
      where: { to_profile_id: { _eq: $profileId } }
      _set: { unread: false }
    ) {
      affected_rows
    }
  }
`

export const M_REGISTER_DEVICE_TOKEN = gql`
  mutation RegisterDeviceToken($profile_id: Int!, $token: String!) {
    insert_profile_devices(
      objects: { profile_id: $profile_id, token: $token }
    ) {
      affected_rows
    }
  }
`

export const M_SEND_CLIENT_EMAIL = gql`
  mutation SendClientEmail(
    $client_name: String!
    $therapist_name: String!
    $therapist_email: String!
    $client_email: String!
  ) {
    assign_client(
      client_name: $client_name
      therapist_name: $therapist_name
      therapist_email: $therapist_email
      client_email: $client_email
    ) {
      success
      message
    }
  }
`
