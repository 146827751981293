import { GetPost_my_connections_posts } from 'operations/GetPost'
import { InsertMention } from 'operations/InsertMention'
import { InsertOnePost } from 'operations/InsertOnePost'

import { M_INSERT_MENTION } from 'lib/mutations/mentions'
import { M_INSERT_POST } from 'lib/mutations/posts'

import { useMutation, useReactiveVar } from '@apollo/client'
import * as linkify from 'linkifyjs'
import { isEmpty, isNil, pathOr } from 'ramda'
import { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { getMentionIdsFromText, replaceMentions, whether } from 'utils'

import { PermissionWrapper } from 'components/common/hocs'
import useLinkPreview from 'components/hooks/usePreviewLink'
import LinkPreview from 'components/link_preview'
import { PostCategory } from 'components/post.interface'
import { Box, Button } from 'components/ui'
import { ImageCrop } from 'components/upload'

import { HomeFeedType, postIdList, postsVar } from 'utils/posts/feed'

import PostBox from './post_box'

interface CreatePostProps {
  fromProfileId: number
}

const CreatePost: FunctionComponent<CreatePostProps> = ({ fromProfileId }) => {
  const [postTitle, setPostTitle] = useState<string>('')
  const [postContent, setPostContent] = useState<string>('')
  const [imageData, setImageData] = useState<ImageCrop[]>([])
  const [isEditingPost, setIsEditingPost] = useState<boolean>(false)
  const [isAnonymousPost, setIsAnonymousPost] = useState<boolean>(false)
  const posts = useReactiveVar(postsVar)

  const linkPreviewUrl = useMemo(() => {
    const results = linkify.find(postContent)
    return whether(!isNil(results) && results.length > 0, results[0]?.href)
  }, [postContent])

  const linkPreview = useLinkPreview(linkPreviewUrl)

  const [insertNewPost] = useMutation<InsertOnePost>(M_INSERT_POST, {
    onCompleted: (post: InsertOnePost) => {
      if (!post.insert_posts_one) return
      postsVar([post.insert_posts_one as HomeFeedType, ...posts])
      postIdList.add(post.insert_posts_one.id)
    },
  })
  const [insertMentionsMutation] = useMutation<InsertMention>(M_INSERT_MENTION)
  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enteredTitle = event.target.value
    setPostTitle(enteredTitle)
  }
  const handleAnonymously = (value: boolean) => setIsAnonymousPost(value)
  const handleChangeContent = (text: string) => {
    const enteredContent = text
    setPostContent(enteredContent)
  }

  const handleEditingChange = (editing: boolean) => {
    setIsEditingPost(editing)
  }

  const handleAddImage = (image: ImageCrop) => {
    setImageData((prev) => [...prev, { ...image }])
  }

  const handleRemoveImage = (imageURL: string) => {
    const imageDataCopy = [...imageData]
    const newSetImages = imageDataCopy.filter((img) => img.image !== imageURL)
    setImageData(newSetImages)
  }
  const resetForm = () => {
    setPostTitle('')
    setPostContent('')
    setImageData([])
    handleEditingChange(false)
  }

  const insertMentions = useCallback(
    ({
      idList,
      fromProfileId,
      postId,
    }: {
      idList: number[]
      fromProfileId: number | null
      postId: number
    }) => {
      const mentions = idList.map((id: number) => ({
        from_profile_id: fromProfileId,
        to_profile_id: id,
        comment_id: null,
        post_id: postId,
      }))

      insertMentionsMutation({ variables: { data: mentions } })
    },
    [insertMentionsMutation],
  )

  const insertPost = async () => {
    const parsedPost = replaceMentions(postContent)
    const response = await insertNewPost({
      variables: {
        author_profile_id: fromProfileId,
        category: PostCategory.POST,
        title: postTitle,
        content: parsedPost,
        images: imageData,
        link_preview: linkPreview,
        is_anonymous: isAnonymousPost,
      },
    })

    const idList = getMentionIdsFromText(parsedPost)
    const { id: postId, author_profile_id } = pathOr(
      {},
      ['data', 'insert_posts_one'],
      response,
    ) as GetPost_my_connections_posts
    insertMentions({ idList, fromProfileId: author_profile_id, postId })
    resetForm()
  }

  const isPostingImages = !isEmpty(imageData)

  return (
    <Box className="flex-col p-7 w-full" adaptToMobile>
      <PostBox
        title={postTitle}
        content={postContent}
        handleChangeTitle={handleChangeTitle}
        handleChangeContent={handleChangeContent}
        handleEditingChange={handleEditingChange}
        handleAddImage={handleAddImage}
        handleRemoveImage={handleRemoveImage}
        isEditingPost={isEditingPost}
        images={imageData}
        handleAnonymously={handleAnonymously}
        isAnonymousPost={isAnonymousPost}
      />
      <LinkPreview linkPreview={linkPreview} />
      {(isEditingPost || isPostingImages) && (
        <div className="flex gap-4 pt-4">
          <PermissionWrapper
            action="InsertPost"
            behavior="Disable"
            tooltip="You don't have permissions to post yet. Your membership application is still being reviewed. Once approved you will have full access. In the meantime you can complete your profile and view different areas of Belongly."
          >
            <Button
              className="flex-1"
              disabled={isEditingPost && isEmpty(postTitle)}
              onClick={insertPost}
              variant="primary"
            >
              Post
            </Button>
          </PermissionWrapper>
          {isEditingPost && (
            <Button
              className="flex-2"
              onClick={() => setIsEditingPost(false)}
              variant="third"
            >
              Cancel
            </Button>
          )}
        </div>
      )}
    </Box>
  )
}

export default CreatePost
