import { isEmpty } from 'ramda'
import { FunctionComponent } from 'react'

import RecentSearchedProfiles from 'components/recent_searched_profiles'
import { RECENT_SEARCHES_KEY } from 'components/search_engine'
import { Box } from 'components/ui'

const RecentSearches: FunctionComponent = () => {
  const recentSearches = JSON.parse(
    localStorage.getItem(RECENT_SEARCHES_KEY) || '[]',
  )

  if (isEmpty(recentSearches)) return <></>

  return (
    <Box title="Recent Searches" isSidebar>
      <div className="flex z-10 flex-row">
        <RecentSearchedProfiles profiles={recentSearches} />
      </div>
    </Box>
  )
}

export default RecentSearches
