import Link from 'next/link'
import { useRouter } from 'next/router'
import { isEmpty, prop } from 'ramda'
import {
  ChangeEventHandler,
  FunctionComponent,
  MouseEventHandler,
  ReactElement,
} from 'react'

import { MentionsInput, PermissionWrapper } from 'components/common/hocs'
import {
  ArticlesIcon,
  BriefcaseIcon,
  ShareAReferralIcon,
  UploadPhotoVideoIcon,
} from 'components/common/icons'
import { useProfile } from 'components/hooks'
import useImageUpload from 'components/hooks/useImageUpload'
import { Button, Text, TextInput } from 'components/ui'
import Upload, { ImageCrop, TrackedFile } from 'components/upload'

import ImageList from '../common/image_list'
import InsertOptions from '../common/insert_options'

type PostOptionLink = {
  variant: 'link'
  href: string
}
type PostOptionExternalLink = {
  variant: 'external-link'
  href: string
}
type PostOptionUpload = {
  variant: 'upload'
  prefix: string
  onSave: (data: ImageCrop) => void
  onImageUploadStatusChange: (file: TrackedFile) => void
}

type IPostOption = {
  variant: 'upload' | 'link' | 'external-link'
  icon: ReactElement
  label: string
  prefix?: string
  onSave?: (data: ImageCrop) => void
  onImageUploadStatusChange?: (file: TrackedFile) => void
  href?: string
}

const PostOption: FunctionComponent<IPostOption> = ({
  variant,
  icon,
  label,
  href = '',
  onSave = () => {},
  prefix = '',
  onImageUploadStatusChange = () => {},
}) => {
  const router = useRouter()
  const handleCreateReferral = () => {
    router.push('/opportunities/referrals/create')
  }

  const Body = () => (
    <div className="flex gap-3 items-center">
      {icon}
      <Text capitalize weight="regular" size="sm">
        {label}
      </Text>
    </div>
  )

  if (variant === 'link') {
    return (
      <PermissionWrapper
        action="CreateReferral"
        behavior="Disable"
        tooltip="You don't have permission to create a referral yet. Your membership application is still being reviewed. Once approved you will have full access. In the meantime you can complete your profile and view different areas of Belongly."
      >
        <Button onClick={handleCreateReferral}>
          <div className="flex py-2 px-4 rounded cursor-pointer hover:bg-gray-200 group">
            <Body />
          </div>
        </Button>
      </PermissionWrapper>
    )
  }

  if (variant === 'external-link') {
    return (
      <div className="flex py-2 px-4 rounded cursor-pointer hover:bg-gray-200 group">
        <a
          href={href}
          target="_blank"
          rel="noreferrer"
          className="flex items-center"
        >
          <Body />
        </a>
      </div>
    )
  }

  return (
    <div className="flex py-2 px-4 rounded cursor-pointer hover:bg-gray-200 group">
      <Upload
        permissions={{
          action: 'InsertPost',
          behavior: 'Disable',
          tooltip:
            "You don't have permission to upload photos yet. Your membership application is still being reviewed. Once approved you will have full access. In the meantime you can complete your profile and view different areas of Belongly.",
        }}
        buttonClass="rounded-full bg-white w-8 h-8 ml-2"
        onSave={onSave}
        prefix={prefix}
        multiple
        onImageUploadStatusChange={onImageUploadStatusChange}
      >
        <Body />
      </Upload>
    </div>
  )
}

interface PostAreaProps {
  title: string
  maxTitleLength?: number
  content: string
  handleChangeTitle?: ChangeEventHandler<HTMLInputElement>
  handleChangeContent?: (text: string) => void
  handleEditingChange?: (editing: boolean) => void
  handleAddImage?: ({ image }: ImageCrop) => void
  handleRemoveImage: (imageURL: string) => void
  images: ImageCrop[]
  isEditingPost: boolean
  onClick?: MouseEventHandler
  isAnonymousPost: boolean
  handleAnonymously: (value: boolean) => void
}

const PostBox: FunctionComponent<PostAreaProps> = ({
  title,
  maxTitleLength = 80,
  content,
  handleChangeTitle,
  handleChangeContent,
  handleEditingChange,
  handleAddImage,
  handleRemoveImage,
  handleAnonymously,
  images,
  isEditingPost,
  isAnonymousPost,
}) => {
  const { profile, id: myId } = useProfile()
  const { handleImageUpload } = useImageUpload()

  const addImage = (image: ImageCrop) => {
    if (handleAddImage) {
      handleAddImage(image)
      return
    }
  }

  return (
    <div className="flex flex-col gap-4">
      {isEditingPost && (
        <div className="flex gap-2 items-center">
          <InsertOptions
            isAnonymousPost={isAnonymousPost}
            options={[
              {
                label: `Post as ${profile?.first_name} ${profile?.last_name}`,
                onClick: () => handleAnonymously(false),
              },
              {
                label: 'Post Anonymously',
                onClick: () => handleAnonymously(true),
              },
            ]}
          />
          <TextInput
            background="white"
            autoFocus
            onFocus={() => handleEditingChange?.(true)}
            onChange={handleChangeTitle}
            placeholder="Title"
            type="text"
            value={title}
            maxLimit={maxTitleLength}
            variant="maxLength"
          />
        </div>
      )}
      <div className="flex flex-col gap-4 z-">
        <PermissionWrapper
          action="InsertPost"
          behavior="Disable"
          tooltip="You don't have permission to create a post yet. Your membership application is still being reviewed. Once approved you will have full access. In the meantime you can complete your profile and view different areas of Belongly."
        >
          <MentionsInput
            content={content}
            onChange={handleChangeContent}
            onClick={() => handleEditingChange?.(true)}
            placeholder="Post, ask a question, or start a discussion"
          />
        </PermissionWrapper>
        {!isEmpty(images) && (
          <ImageList
            images={images}
            addImage={handleAddImage}
            isEditingPost={isEditingPost}
            removeImage={handleRemoveImage}
            withButton={false}
          />
        )}
        <div className="flex justify-left">
          <PostOption
            label="Photo"
            icon={<UploadPhotoVideoIcon />}
            variant="upload"
            onSave={(props) => addImage(props)}
            prefix={`post-image/${myId}`}
            onImageUploadStatusChange={handleImageUpload}
          />
          <PostOption
            variant="link"
            href="/opportunities/referrals/create"
            icon={<ShareAReferralIcon />}
            label="Referral"
          />
          {/*
            <PostOption
              variant="external-link"
              href="https://www.belongly.com/publish/"
              label="Write an article"
              icon={<ArticlesIcon />}
            />
            <PostOption
              variant="external-link"
              href="https://www.belongly.com/mental-health-professional-jobs/"
              label="Mental Health Jobs"
              icon={<BriefcaseIcon />}
            />
          */}
        </div>
      </div>
    </div>
  )
}

export default PostBox
