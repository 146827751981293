import type { GetProfileByOwnerId_profiles } from 'operations/GetProfileByOwnerId'
import type { LikedProfiles } from 'operations/LikedProfiles'

import {
  BookOpenIcon,
  ClockIcon,
  NewspaperIcon,
  SearchIcon,
} from '@heroicons/react/outline'
import { FunctionComponent } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { twMerge } from 'tailwind-merge'

import { ChevronUpIcon } from 'components/common/icons'
import { useNewPostsNotification } from 'components/hooks/useNewPostsNotification'
import usePosts from 'components/hooks/usePosts'
import { PostCategory } from 'components/post.interface'
import { Button, Menu, Text } from 'components/ui'

import { Post } from './post/post'

export interface SeeWhatsNewProps {
  readerProfile: Pick<GetProfileByOwnerId_profiles, 'id' | 'avatar'>
}

enum AdditionalFilter {
  MOST_RECENT = 'Most Recent',
}

const LoadingComponent = ({ divsCount = 6 }: { divsCount?: number }) => (
  <div className="container m-10 mx-auto mb-20">
    <div className="grid grid-cols-1 gap-6">
      {new Array(divsCount).fill({}).map((_, index) => (
        <div
          className="h-56 animate-pulse md:rounded bg-gray-light"
          key={`${index}`}
        />
      ))}
    </div>
  </div>
)

const SeeWhatsNew: FunctionComponent<SeeWhatsNewProps> = ({
  readerProfile,
}) => {
  const {
    filter,
    setFilter,
    posts,
    errorPostData,
    isLoading,
    refetch,
    getNextDataChunk,
  } = usePosts({
    readerProfile,
  })

  const { hasNewPosts, addNewPostsToFeed } = useNewPostsNotification()

  if (errorPostData) {
    return (
      <div className="flex flex-col gap-5 items-center p-20">
        <Text>Error loading posts.</Text>
        <Button variant="primary" onClick={() => refetch()}>
          Reload
        </Button>
      </div>
    )
  }

  const likedPost = (profiles: LikedProfiles[] | undefined) => {
    if (!profiles) return false
    const profile = profiles.find((p) => p.from_profile_id === readerProfile.id)
    return profile?.from_profile_id === readerProfile.id
  }

  const selectedFilter = {
    [AdditionalFilter.MOST_RECENT]: 'Most Recent',

    [PostCategory.POST]: 'Posts',

    [PostCategory.REFERRAL]: 'Referrals',

    [PostCategory.NEWS]: 'News',
  }[filter]

  const handleSeeWhatsNew = () => {
    addNewPostsToFeed()
    window.scrollTo({
      top: 300,
      behavior: 'smooth',
    })
  }

  return (
    <div className="relative">
      <div className="flex flex-row-reverse px-4 mb-6">
        <Menu
          options={[
            {
              label: 'Most Recent',
              onClick: () => setFilter(AdditionalFilter.MOST_RECENT),
              icon: (active: boolean) => (
                <ClockIcon
                  className={twMerge(active && 'stroke-white', 'h-4 w-4')}
                />
              ),
            },
            {
              label: 'Posts',
              onClick: () => setFilter(PostCategory.POST),
              icon: (active: boolean) => (
                <BookOpenIcon
                  className={twMerge(active && 'stroke-white', 'h-4 w-4')}
                />
              ),
            },
            {
              label: 'Referrals',
              onClick: () => setFilter(PostCategory.REFERRAL),
              icon: (active: boolean) => (
                <SearchIcon
                  className={twMerge(active && 'stroke-white', 'h-4 w-4')}
                />
              ),
            },
            {
              label: 'News',
              onClick: () => setFilter(PostCategory.NEWS),
              icon: (active: boolean) => (
                <NewspaperIcon
                  className={twMerge(active && 'stroke-white', 'h-4 w-4')}
                />
              ),
            },
          ]}
          button={
            <div className="flex items-center">
              <Text variant="small" color="black">
                Filter by: {selectedFilter}
              </Text>
              <ChevronUpIcon size={3} className="ml-2 rotate-180" />
            </div>
          }
        />
      </div>
      {hasNewPosts && (
        <Button
          variant="secondary"
          className="flex sticky top-10 left-1/2 z-50 gap-4 items-center transform -translate-x-1/2"
          onClick={handleSeeWhatsNew}
        >
          <ChevronUpIcon className="w-3 h-3 fill-white" />
          See what&apos;s new
        </Button>
      )}
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <div id="infiniteScrollContainer">
          <InfiniteScroll
            dataLength={posts?.length ?? 0}
            next={getNextDataChunk}
            hasMore={true}
            endMessage={<p>No more data</p>}
            loader={<LoadingComponent divsCount={3} />}
            style={{
              overflow: 'visible',
              display: 'flex',
              gap: '2rem',
              flexDirection: 'column',
            }}
          >
            {posts?.map((post, index) => (
              <div data-post-id={post.id} key={index}>
                <Post
                  post={post}
                  likedPost={
                    'liked_profiles' in post
                      ? likedPost(post?.liked_profiles)
                      : false
                  }
                  reader={readerProfile}
                  showAllComments
                  isOnFeed
                />
              </div>
            ))}
          </InfiniteScroll>
        </div>
      )}
    </div>
  )
}

export default SeeWhatsNew
