import { GetProfileByOwnerId_profiles } from 'operations/GetProfileByOwnerId'
import { GetTokens } from 'operations/GetTokens'
import { RegisterDeviceToken } from 'operations/RegisterDeviceToken'

import { M_REGISTER_DEVICE_TOKEN } from 'lib/mutations/notifications'
import { Q_GET_TOKENS } from 'lib/queries/notification'

import UserInfo from '@/components/home/post/common/user_info/user_info_card'
import CreatePost from '@/components/home/post/create_post/create_post'
import { GetFullProfile_profiles } from '@/operations/GetFullProfile'
import { useMutation, useQuery } from '@apollo/client'
import { useUser } from '@clerk/nextjs'
import type { NextPage } from 'next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { isEmpty, isNil } from 'ramda'
import React, { FunctionComponent, useEffect } from 'react'

import MyBusinessPages from 'components/business_profiles/my_business_pages'
import RecentSearches from 'components/home/recent_search'
import { useProfile } from 'components/hooks'
import PeopleToAccept from 'components/profile/people_to_accept_invitation'
import PeopleYouMayKnow from 'components/profile/people_you_may_know'
import { Text } from 'components/ui'

import ThreeCol from '../components/common/threecol'
import SeeWhatsNew from '../components/home/see_whats_new'

interface ProfileProps {
  profile?: GetProfileByOwnerId_profiles | GetFullProfile_profiles | undefined
  userId?: string
}

const LeftCol: FunctionComponent<ProfileProps> = ({ profile }) => {
  return (
    <div className="flex flex-col gap-6 w-full">
      {profile && <UserInfo profile={profile} />}
      <RecentSearches />
      {profile && <MyBusinessPages profile={profile} isOwner={true} />}
    </div>
  )
}

const RightCol: FunctionComponent<ProfileProps> = ({ profile, userId }) => {
  return (
    <>
      <div className="flex flex-col gap-6 w-full">
        <PeopleToAccept toProfileId={profile ? profile.id : -1} />
        {userId && <PeopleYouMayKnow userId={userId} />}

        <div className="flex flex-wrap gap-1 justify-center items-center">
          <Link
            passHref
            href="https://www.belongly.com/terms-of-service/"
            target="_blank"
          >
            <Text variant="extra-small" className="hover:text-primary">
              Terms of Service
            </Text>
          </Link>
          <Text variant="extra-small">|</Text>

          <Link
            href="https://www.belongly.com/privacy-policy/"
            target="_blank"
            passHref
          >
            <Text variant="extra-small" className="hover:text-primary">
              Privacy Policy
            </Text>
          </Link>
          <Text variant="extra-small">|</Text>
          <Link
            href="https://www.belongly.com/california-privacy-policy/"
            target="_blank"
            passHref
          >
            <Text variant="extra-small" className="hover:text-primary">
              California Privacy Policy
            </Text>
          </Link>
        </div>
      </div>
    </>
  )
}

const Home: NextPage = () => {
  const { user } = useUser()

  const { id: myId } = useProfile()

  const { profile, loading } = useProfile()

  const [registerToken] = useMutation<RegisterDeviceToken>(
    M_REGISTER_DEVICE_TOKEN,
  )

  const {
    query: { deviceToken },
  } = useRouter()

  const { data: tokens, loading: tokensLoading } = useQuery<GetTokens>(
    Q_GET_TOKENS,
    {
      variables: {
        profileId: myId,
      },
    },
  )

  const tokenIsAlreadyRegistred = tokens?.profile_devices
    .map(({ token }) => token)
    .includes(String(deviceToken))

  const shouldRegisterToken =
    !isEmpty(deviceToken) &&
    !isNil(deviceToken) &&
    !isNil(myId) &&
    !tokensLoading &&
    !tokenIsAlreadyRegistred

  useEffect(() => {
    if (shouldRegisterToken) {
      registerToken({
        variables: {
          profile_id: myId,
          token: deviceToken,
        },
      })
    }
  }, [
    deviceToken,
    myId,
    registerToken,
    shouldRegisterToken,
    tokenIsAlreadyRegistred,
    tokensLoading,
  ])

  if (loading && !profile) {
    return (
      <ThreeCol
        left={<div className="h-96 animate-pulse md:rounded bg-gray-light" />}
        right={<div className="h-96 animate-pulse md:rounded bg-gray-light" />}
      >
        <div className="grid grid-cols-1 gap-6">
          {new Array(6).fill({}).map((_, index) => (
            <div
              className="h-56 animate-pulse md:rounded bg-gray-light"
              key={`${index}`}
            />
          ))}
        </div>
      </ThreeCol>
    )
  }

  return (
    <ThreeCol
      left={<LeftCol profile={profile} />}
      right={<RightCol userId={user?.id} profile={profile} />}
    >
      {profile && (
        <div className="flex flex-col gap-6">
          <CreatePost fromProfileId={profile.id} />
          <SeeWhatsNew readerProfile={profile} />
        </div>
      )}
    </ThreeCol>
  )
}
export default Home
