import {
  BusinessProfile,
  Comments,
  CommonFields,
  LikedProfiles,
  News,
  OpportunitiesFragment,
  ProfileFields,
} from 'lib/queries/posts'

import { gql } from '@apollo/client'

export const S_GET_NEW_NMC_POSTS = gql`
  ${CommonFields}
  ${News}
  ${ProfileFields}
  ${LikedProfiles}
  ${Comments}
  ${OpportunitiesFragment}
  ${BusinessProfile}
  subscription OnNewPosts($time: timestamptz!) {
    my_connections_posts: posts(
      where: {
        _and: [
          { created_at: { _gt: $time } }
          { author_profile_id: { _is_null: false } }
        ]
      }
      order_by: { created_at: desc }
    ) {
      ...CommonFields
      author {
        ...ProfileFields
      }
      business {
        ...BusinessProfile
      }
      liked_profiles {
        ...LikedProfiles
      }
      news {
        ...NewsFields
      }
      liked_profiles_aggregate {
        aggregate {
          count(columns: from_profile_id)
        }
      }
      comments_aggregate {
        aggregate {
          count(columns: from_profile_id)
        }
      }
    }
  }
`

export const S_GET_NEW_NMC_COMMENTED_POSTS = gql`
  ${CommonFields}
  ${News}
  ${ProfileFields}
  ${LikedProfiles}
  ${Comments}
  ${OpportunitiesFragment}
  ${BusinessProfile}
  subscription OnNewPostsCommented($time: timestamptz!, $profileId: Int!) {
    not_my_connections_commented_posts: post_comments(
      where: {
        _and: [
          { created_at: { _gt: $time } }
          {
            author: { connections_to: { from_profile_id: { _eq: $profileId } } }
          }
        ]
      }
    ) {
      ...CommentsFields
      post {
        ...CommonFields
        author {
          ...ProfileFields
        }
        liked_profiles {
          ...LikedProfiles
        }
        liked_profiles_aggregate {
          aggregate {
            count(columns: from_profile_id)
          }
        }
        comments_aggregate {
          aggregate {
            count(columns: from_profile_id)
          }
        }
        news {
          ...NewsFields
        }
      }
    }
  }
`

export const S_GET_NEW_BELONGLY_NEWS_POSTS = gql`
  ${CommonFields}
  ${News}
  ${ProfileFields}
  ${LikedProfiles}
  ${Comments}
  ${OpportunitiesFragment}
  ${BusinessProfile}
  subscription OnNewBelonglyNewsPosts($time: timestamptz!) {
    belongly_news_posts: posts(
      where: {
        _and: [
          { release_date: { _gt: $time } }
          {
            _and: [
              { post_category: { type: { _eq: "NEWS" } } }
              { news: { news_source: { url: { _ilike: "%belongly%" } } } }
            ]
          }
        ]
      }
      order_by: { release_date: desc }
    ) {
      ...CommonFields
      author {
        ...ProfileFields
      }
      liked_profiles {
        ...LikedProfiles
      }
      liked_profiles_aggregate {
        aggregate {
          count(columns: from_profile_id)
        }
      }
      comments_aggregate {
        aggregate {
          count(columns: from_profile_id)
        }
      }
      news {
        ...NewsFields
      }
    }
  }
`

export const S_GET_NEW_LIKED_POSTS = gql`
  ${CommonFields}
  ${News}
  ${ProfileFields}
  ${LikedProfiles}
  ${Comments}
  ${OpportunitiesFragment}
  ${BusinessProfile}
  subscription OnNewLikedPosts($time: timestamptz!, $profileId: Int!) {
    not_my_connections_liked_posts: liked_posts(
      where: {
        _and: [
          { created_at: { _gt: $time } }
          {
            from_profile: {
              connections_to: { from_profile_id: { _eq: $profileId } }
            }
          }
        ]
      }
      order_by: { post_id: desc }
      distinct_on: post_id
    ) {
      ...LikedProfiles
      post: to_post {
        ...CommonFields
        author {
          ...ProfileFields
        }
        liked_profiles {
          ...LikedProfiles
        }
        liked_profiles_aggregate {
          aggregate {
            count(columns: from_profile_id)
          }
        }
        comments_aggregate {
          aggregate {
            count(columns: from_profile_id)
          }
        }
        news {
          ...NewsFields
        }
      }
    }
  }
`

export const S_GET_NEW_OPPORTUNITIES = gql`
  ${OpportunitiesFragment}
  subscription OnNewOpportunities($time: timestamptz!) {
    opportunities(
      where: {
        _and: [
          { created_at: { _gt: $time } }
          { live: { _eq: true } }
          { category: { _eq: "Referral" } }
          { is_deleted: { _eq: false } }
        ]
      }
      order_by: { created_at: desc }
    ) {
      ...Opportunity
    }
  }
`

export const S_GET_NEW_BUSINESS_POSTS = gql`
  ${CommonFields}
  ${News}
  ${ProfileFields}
  ${LikedProfiles}
  ${Comments}
  ${OpportunitiesFragment}
  ${BusinessProfile}
  subscription OnNewBusinessPosts($time: timestamptz!) {
    business_posts: posts(
      where: {
        _and: [
          { created_at: { _gt: $time } }
          { business_id: { _is_null: false } }
        ]
      }
      order_by: { created_at: desc }
    ) {
      ...CommonFields
      author {
        ...ProfileFields
      }
      business {
        ...BusinessProfile
      }
      liked_profiles {
        ...LikedProfiles
      }
      news {
        ...NewsFields
      }
      liked_profiles_aggregate {
        aggregate {
          count(columns: from_profile_id)
        }
      }
      comments_aggregate {
        aggregate {
          count(columns: from_profile_id)
        }
      }
    }
  }
`
