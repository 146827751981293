import { GetFullProfile_profiles } from 'operations/GetFullProfile'
import { GetProfileByOwnerId_profiles } from 'operations/GetProfileByOwnerId'

import Link from 'next/link'
import { FunctionComponent } from 'react'

import CurrentJob from 'components/common/current_job'
import useBusinessProfiles from 'components/hooks/useBusinessProfiles'
import AvailabilityToggle from 'components/profile/referral_section/availability_toggle'
import { Availabilities } from 'components/profile/user_basic_details/availability_badge'
import { Avatar, Box, Text } from 'components/ui'

interface Profile {
  profile: GetProfileByOwnerId_profiles | GetFullProfile_profiles | undefined
  showStatus?: boolean
}
const UserInfo: FunctionComponent<Profile> = ({
  profile,
  showStatus = true,
}) => {
  const businessProfiles = useBusinessProfiles(profile?.id)
  const connectionsNumber = Math.max(
    profile?.connections_from_aggregate.aggregate?.count || 0,
    profile?.connections_to_aggregate.aggregate?.count || 0,
  )

  const fullName = `${profile?.first_name} ${profile?.last_name}`.trim()

  return (
    <Box
      className="flex flex-col gap-5 p-0 text-center"
      footer={
        showStatus ? (
          <AvailabilityToggle
            hasLabel
            availabilityValue={profile?.availability as Availabilities}
          />
        ) : null
      }
    >
      <div className="flex flex-col justify-center items-center">
        <div className="py-6 px-4 w-full rounded-t bg-primary" />
        <div className="flex relative flex-col w-full">
          <div className="h-12 bg-primary" />
          <div className="h-12 bg-white" />
          <div className="overflow-hidden absolute top-1/2 left-1/2 w-24 h-24 rounded-full border-white transform -translate-x-1/2 -translate-y-1/2">
            <Link href={`/be/${profile?.username}`} passHref prefetch={false}>
              <Avatar
                profile={{ avatar: profile?.avatar }}
                width={110}
                height={110}
                borderColor="white"
                className="object-cover w-24 h-24 rounded-full"
                size={24}
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2 px-4 pb-4 mb-4 -mt-2">
        <Link href={`/be/${profile?.username}`} passHref prefetch={false}>
          <Text variant="subheading" className="mb-2" weight="semibold">
            {fullName}
          </Text>
          <CurrentJob
            currentJob={profile?.current_job_new}
            experiences={profile?.experiences}
          />
        </Link>
        <Link href="/connections" className="text-sm" passHref>
          <Text variant="small">
            {connectionsNumber} Connection{connectionsNumber != 1 && 's'}
          </Text>
        </Link>
        <Link href={`/be/${profile?.username}`} prefetch={false} passHref>
          <Text variant="small" color="fourth">
            View my Profile
          </Text>
        </Link>

        {businessProfiles?.map((businessProfile) => (
          <Link
            key={businessProfile.id}
            href={`/page/org/${businessProfile.username}`}
            passHref
          >
            <Text variant="small" capitalize>
              {businessProfile.name}
            </Text>
          </Link>
        ))}
      </div>
    </Box>
  )
}

export default UserInfo
