import {
  OnNewBelonglyNewsPosts,
  OnNewBelonglyNewsPostsVariables,
} from 'operations/OnNewBelonglyNewsPosts'
import {
  OnNewBusinessPosts,
  OnNewBusinessPostsVariables,
} from 'operations/OnNewBusinessPosts'
import {
  OnNewLikedPosts,
  OnNewLikedPostsVariables,
} from 'operations/OnNewLikedPosts'
import {
  OnNewOpportunities,
  OnNewOpportunitiesVariables,
} from 'operations/OnNewOpportunities'
import { OnNewPosts, OnNewPostsVariables } from 'operations/OnNewPosts'
import {
  OnNewPostsCommented,
  OnNewPostsCommentedVariables,
} from 'operations/OnNewPostsCommented'

import {
  S_GET_NEW_BELONGLY_NEWS_POSTS,
  S_GET_NEW_BUSINESS_POSTS,
  S_GET_NEW_LIKED_POSTS,
  S_GET_NEW_NMC_COMMENTED_POSTS,
  S_GET_NEW_NMC_POSTS,
  S_GET_NEW_OPPORTUNITIES,
} from 'lib/subscriptions/posts'

import { useReactiveVar, useSubscription } from '@apollo/client'
import { useMemo, useState } from 'react'

import {
  HomeFeedType,
  mergeFeedData,
  postIdList,
  postsVar,
} from 'utils/posts/feed'

import useProfile from './useProfile'

export function useNewPostsNotification() {
  const cachedPosts = useReactiveVar(postsVar)
  const profile = useProfile()
  const [lastRenderedPostDate, setLastRenderedPostDate] = useState(
    cachedPosts?.[0]?.created_at ?? new Date(),
  )
  const [hasNewPosts, setHasNewPosts] = useState(false)

  const { data: nmcPosts } = useSubscription<OnNewPosts, OnNewPostsVariables>(
    S_GET_NEW_NMC_POSTS,
    {
      variables: {
        time: lastRenderedPostDate,
      },
    },
  )

  const { data: nmcCommentedPosts } = useSubscription<
    OnNewPostsCommented,
    OnNewPostsCommentedVariables
  >(S_GET_NEW_NMC_COMMENTED_POSTS, {
    variables: {
      time: lastRenderedPostDate,
      profileId: profile?.id ?? 0,
    },
    skip: !profile,
  })

  const { data: belonglyNewsPosts } = useSubscription<
    OnNewBelonglyNewsPosts,
    OnNewBelonglyNewsPostsVariables
  >(S_GET_NEW_BELONGLY_NEWS_POSTS, {
    variables: {
      time: lastRenderedPostDate,
    },
  })

  const { data: likedPosts } = useSubscription<
    OnNewLikedPosts,
    OnNewLikedPostsVariables
  >(S_GET_NEW_LIKED_POSTS, {
    variables: {
      time: lastRenderedPostDate,
      profileId: profile?.id ?? 0,
    },
    skip: !profile,
  })

  const { data: opportunitiesData } = useSubscription<
    OnNewOpportunities,
    OnNewOpportunitiesVariables
  >(S_GET_NEW_OPPORTUNITIES, {
    variables: {
      time: lastRenderedPostDate,
    },
  })

  const { data: businessPostsData } = useSubscription<
    OnNewBusinessPosts,
    OnNewBusinessPostsVariables
  >(S_GET_NEW_BUSINESS_POSTS, {
    variables: {
      time: lastRenderedPostDate,
    },
  })

  const { my_connections_posts } = nmcPosts ?? { my_connections_posts: [] }
  const { not_my_connections_commented_posts } = nmcCommentedPosts ?? {
    not_my_connections_commented_posts: [],
  }
  const { belongly_news_posts } = belonglyNewsPosts ?? {
    belongly_news_posts: [],
  }
  const { not_my_connections_liked_posts } = likedPosts ?? {
    not_my_connections_liked_posts: [],
  }
  const { opportunities } = opportunitiesData ?? {
    opportunities: [],
  }
  const { business_posts } = businessPostsData ?? {
    business_posts: [],
  }

  const posts = useMemo(() => {
    const { posts: _posts } = mergeFeedData({
      not_my_connections_commented_posts,
      my_connections_posts,
      belongly_news_posts,
      not_my_connections_liked_posts,
      opportunities,
      business_posts,
      followed_news_posts: [],
    })

    if (_posts.length > 0 && !hasNewPosts) {
      setHasNewPosts(true)
    }

    return _posts

    // eslint-disable-next-line
  }, [
    not_my_connections_commented_posts,
    my_connections_posts,
    belongly_news_posts,
    not_my_connections_liked_posts,
    opportunities,
    business_posts,
  ])

  function updateLastDate(date: number) {
    const secondMS = 1000
    setLastRenderedPostDate(new Date(date + secondMS).toUTCString())
  }

  function addNewPostsToFeed() {
    if (posts.length < 1) {
      return
    }

    const incommingPosts = posts

    incommingPosts.sort(
      (a, b) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
    )

    let _cachedPosts = [...postsVar()]
    let maxDate = 0
    // Iterate over the posts we got from the subscription
    incommingPosts.forEach((post) => {
      if (new Date(post.sort_date).getTime() > maxDate) {
        maxDate = new Date(post.sort_date).getTime()
      }

      // Check if the post is already in the feed
      if (postIdList.has(post.id)) {
        _cachedPosts = [
          post as HomeFeedType,
          ..._cachedPosts.filter((p) => p.id !== post.id),
        ]
      } else {
        _cachedPosts = [post as HomeFeedType, ..._cachedPosts]
        postIdList.add(post.id)
      }
    })

    postsVar(_cachedPosts)

    updateLastDate(maxDate)
    setHasNewPosts(false)
  }

  return {
    hasNewPosts,
    addNewPostsToFeed,
  }
}
